import React from 'react';
import PropTypes from 'prop-types';
import CccisdHeader from 'cccisd-header';
import style from './style.css';

import IconClipboard from 'cccisd-icons/clipboard4';
import IconGraduation from 'cccisd-icons/graduation';
import IconHome from 'cccisd-icons/home3';
import IconManage from 'cccisd-icons/user-plus2';
import IconOffice from 'cccisd-icons/office';
import IconReading from 'cccisd-icons/reading';
import IconSearch from 'cccisd-icons/search2';
import IconSync from 'cccisd-icons/cloud-check';
import IconUsers from 'cccisd-icons/users4';

const iconMapper = {
    IconClipboard,
    IconGraduation,
    IconHome,
    IconManage,
    IconOffice,
    IconReading,
    IconSearch,
    IconSync,
    IconUsers,
};

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const Header = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];

    function addIconToNav(nav, isChild = false) {
        const IconComponent = nav.icon && iconMapper[nav.icon];
        const icon =
            IconComponent &&
            (() => (
                <span className={style.icon} style={isChild ? { width: '25px' } : {}}>
                    <IconComponent />
                </span>
            ));

        const children = nav.children && nav.children.map(n => addIconToNav(n, true));

        return {
            ...nav,
            children,
            icon,
        };
    }

    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
        primaryNavLinks = primaryNavLinks.map(n => addIconToNav(n));
    } catch (e) {
        // meh, leave it empty
    }

    return (
        <CccisdHeader
            containerClassName={className}
            logoClassName={style.logo}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            showHomeAction={false}
            {...(noLogoLink ? { logoLink: null } : {})}
        />
    );
};

Header.defaultProps = {
    className: 'container',
    // Students should not be allowed to navigate away from survey
    noLogoLink: Fortress.user.acting.role.handle === 'learner',
};

Header.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default Header;
