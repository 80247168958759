import React from 'react';
import LoginForm from 'cccisd-laravel-nexus/es/components/LoginForm';
import Style from './style.css';
import logo from '../../../images/insights_logo_with_tagline.svg';

var Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    componentDidMount() {
        if (Fortress.auth()) {
            window.location = window.cccisd.boilerplate.url('authRedirect');
        }
    }

    render() {
        return (
            <div style={{ marginTop: '20px' }}>
                <div className="row">
                    <div className="col-md-8">
                        <div>
                            <h1>
                                <b>Welcome to Insights Student Measurement</b>
                            </h1>
                            <img src={logo} alt="Insights Logo" className={Style.logo} />
                        </div>
                        <div className={Style.welcomeText}>
                            <p>
                                Questions? Click to <a href="https://7mindsets.com/contact-support/">Contact Us</a> or{' '}
                                <a href="https://7mindsets.com/insights/">Learn More</a>.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {!Fortress.auth() && <LoginForm showPasswordToggle showPasswordByDefault={false} />}
                    </div>
                </div>
            </div>
        );
    }
}
