import React from 'react';
import _get from 'lodash/get';

import { client, gql } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import { Manage } from 'cccisd-laravel-appdefs';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import Tabs from 'cccisd-tabs';

import ReportList from '../ReportList';
import Table from '../ScoresTable';
import TeacherTableByGroup from './TeacherTableByGroup.js';
import style from './style.css';
import pastYearGroupsQuery from './pastYearGroups.graphql';
import TeacherProgressRender from '../../renders/TeacherProgress';

import { LEGACY_RATING_FORM_ASSIGNMENT_HANDLE, RATING_FORM_ASSIGNMENT_HANDLE } from '../TeacherAdministrative';
const Fortress = window.cccisd && window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;
const rolesWithManage = ['uberadmin', 'orgAdmin', 'guAdmin'];

export default class Administrative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tabs: [],
            selectedStudentTimepoint: '1',
            selectedTeacherTimepoint: '1',
            studentView: 'studentsOverall',
            teacherView: 'teacherProgress',
        };
        this.groupId = Fortress.user.acting.group_id;
        this.actingHandle = Fortress.user.acting.role.handle;
        this.orgAdminReportGroupsResponse = null;
    }

    static defaultProps = {
        studentViews: [],
        teacherViews: [],
    };

    componentDidMount() {
        this.processTabs();
    }

    componentDidUpdate(prevProps) {
        // teachers only, forces processTabs to run
        // when a different class is selected
        if (prevProps.classId && prevProps.classId !== this.props.classId) {
            this.processTabs();
        }
    }

    onClickStudentTimepoint = selectedStudentTimepoint => {
        if (selectedStudentTimepoint === this.state.selectedStudentTimepoint) {
            return;
        }
        this.setState({ selectedStudentTimepoint }, () => {
            this.processTabs();
        });
    };

    onClickTeacherTimepoint = selectedTeacherTimepoint => {
        if (selectedTeacherTimepoint === this.state.selectedTeacherTimepoint) {
            return;
        }
        this.setState({ selectedTeacherTimepoint }, () => {
            this.processTabs();
        });
    };

    processTabs = async () => {
        const { assignmentPlanQuery, archivedReportFileList, manageProps } = this.props;
        const { selectedStudentTimepoint, selectedTeacherTimepoint } = this.state;

        let pastYearGroups = [];

        // TODO: migration, pawnHook, and graphql support so past_year_pivot works with orgAdmin
        if (this.actingHandle === 'orgAdmin') {
            let resp;
            // only run once, not every time processTabs fires
            if (this.orgAdminReportGroupsResponse) {
                resp = this.orgAdminReportGroupsResponse;
            } else {
                resp = await axios.get(Boilerplate.route('app.orgAdmin.reportGroups'));
                this.orgAdminReportGroupsResponse = resp;
            }

            if (resp.status !== 200) {
                notification({
                    message: 'Error loading reports',
                    data: resp.data.data,
                    duration: 0,
                    type: 'danger',
                });
                return;
            }

            const { pastAndCurrentDistricts } = resp.data.data || {};

            if (Array.isArray(pastAndCurrentDistricts)) {
                pastYearGroups = pastAndCurrentDistricts;
            }
        } else {
            const pastYearGroupsResponse = await client.query({ query: pastYearGroupsQuery });
            pastYearGroups = _get(pastYearGroupsResponse, 'data.roles.anyRole.pastYearGroupsList', []) || [];
        }

        // construct mysql regex in format `(groupid|groupid|groupid|...)`
        let pastYearGroupRegex = pastYearGroups.reduce((reg, group) => {
            return reg + `${group.groupId}|`;
        }, '');
        if (pastYearGroupRegex.endsWith('|')) {
            pastYearGroupRegex = pastYearGroupRegex.slice(0, pastYearGroupRegex.lastIndexOf('|'));
        }
        pastYearGroupRegex = '(' + pastYearGroupRegex + ')';

        const response = await client.query({
            query: gql`
                ${assignmentPlanQuery}
            `,
            variables: {
                classId: this.props.classId, // teachers only
                pastYearGroupRegex,
            },
            // teachers need query to re-run every time they select different class
            fetchPolicy: this.actingHandle === 'instructor' ? 'network-only' : 'cache-first',
        });

        const studentPlanList = _get(response, 'data.groups.organization.learnerPlanList', []);

        /*
            {
                <district groupId>: {
                    plan_0: <assignment plan id>
                    ...
                }
            }
        */
        let defaultAssignmentPlans;
        if (this.actingHandle === 'orgAdmin') {
            const guList = _get(response, 'data.groups.groupingUnitList', []) || [];
            defaultAssignmentPlans = guList.reduce((total, currGu) => {
                return {
                    ...total,
                    [currGu.group.groupId]: _get(currGu, 'group.settings.defaultAssignmentPlans', {}) || {},
                };
            }, {});
        } else {
            const gu = _get(response, 'data.groups.groupingUnit');
            defaultAssignmentPlans = {
                [gu.group.groupId]: _get(gu, 'group.settings.defaultAssignmentPlans', {}) || {},
            };
        }

        const classList = _get(response, 'data.groups.classList', []) || [];
        const reportData = _get(response, 'data.resources', {});
        const blockScoreAccess = _get(Fortress, 'user.acting.role_data.blockScoreAccess', false);
        const blockReportAccess = _get(Fortress, 'user.acting.role_data.blockReportAccess', false);
        const studentInfo = this.processStudentPlans(studentPlanList, defaultAssignmentPlans, classList);

        const studentTimepoints = studentInfo.timepoints;
        const teacherInfo = this.processTeacherPlans(response, defaultAssignmentPlans);

        const studentTimepoint = studentTimepoints.size === 1 ? [...studentTimepoints][0] : selectedStudentTimepoint;

        let tabs = [this.processStudentTab(studentInfo, studentTimepoint)];

        if (!blockScoreAccess) {
            tabs = [...tabs, ...this.processScoringTabs(studentInfo, studentTimepoint)];
        }

        if (teacherInfo.isShowTab) {
            tabs = [...tabs, this.processTeacherTab(teacherInfo, selectedTeacherTimepoint)];
        }

        let fileList = Array.isArray(reportData.fileList) ? reportData.fileList : [];
        if (fileList.length && Array.isArray(archivedReportFileList)) {
            archivedReportFileList.forEach(file => {
                if (!fileList.some(f => f.folder.path === file.folder.path && f.filename === file.filename)) {
                    fileList.push(file);
                }
            });
        }

        if (rolesWithManage.includes(this.actingHandle) && !Fortress?.user?.acting?.role_data?.blockManageAccess) {
            const manageTab = {
                name: 'manage',
                title: 'Manage',
                content: <Manage {...manageProps} />,
            };
            tabs = [...tabs, manageTab];
        }

        if (!blockReportAccess) {
            const reportsTab = {
                name: 'reports',
                title: 'Reports',
                content: <ReportList fileList={fileList} orgAdminDropdown={pastYearGroups} />,
            };
            tabs = [...tabs, reportsTab];
        }

        this.setState({
            tabs,
            selectedStudentTimepoint: studentTimepoint,
            isLoading: false,
        });
    };

    onChangeStudentView = event => {
        this.setState({ studentView: event.target.value }, () => {
            this.processTabs();
        });
    };

    onChangeTeacherView = event => {
        this.setState({ teacherView: event.target.value }, () => {
            this.processTabs();
        });
    };

    processStudentTab = (studentInfo, timepoint) => {
        const { studentView } = this.state;
        const { studentViews, classId } = this.props;

        const options = studentViews.map(view => (
            <option value={view.handle} key={view.handle}>
                {view.label}
            </option>
        ));

        // classId is undefined for non-teacher roles
        if (classId === '') {
            return {
                name: 'Students',
                title: 'Students',
                content: (
                    <p>
                        This teacher account is not linked to any current-year classes.
                        <br />
                        <br />
                        Please contact your administrator for assistance.
                    </p>
                ),
            };
        }

        let tableProps = this.props[studentView];

        tableProps.columns.forEach(col => {
            col.setStyle = (value, row) =>
                classId && row['group.groupId'] === classId ? { backgroundColor: 'rgb(255, 255, 220)' } : {};
            if (col.handle === 'status') {
                col.label = `Time ${timepoint}: Status`;
            }
            if (col.handle === 'started') {
                col.label = `Time ${timepoint}: Started`;
            }
            if (col.handle === 'completed') {
                col.label = `Time ${timepoint}: Completed`;
            }
            if (col.handle === 'percent') {
                col.label = `Time ${timepoint}: % Completed`;
                col.sort = false;
            }
        });

        return {
            name: 'Students',
            title: 'Students',
            content: (
                <div>
                    <div className={style.controlContainer}>
                        {this.renderTimepointPills(studentInfo.timepoints, timepoint, this.onClickStudentTimepoint)}
                        <div className={style.viewSelect}>
                            <select
                                name="studentView"
                                id="studentView"
                                value={studentView}
                                onChange={this.onChangeStudentView}
                                className={style.studentSelect}
                            >
                                {options}
                            </select>
                        </div>
                    </div>
                    <Table
                        key={studentView}
                        {...tableProps}
                        graphqlVariables={{
                            timepoint,
                            classId,
                            teacherAssignmentHandles: [
                                LEGACY_RATING_FORM_ASSIGNMENT_HANDLE,
                                RATING_FORM_ASSIGNMENT_HANDLE,
                            ],
                        }}
                        noRecordsMessage="No records to display."
                    />
                </div>
            ),
        };
    };

    processScoringTabs = (studentInfo, timepoint) => {
        const { selwebTabPropsEE, selwebTabPropsLE, selwebTabPropsMS, selwebTabPropsHS, classId } = this.props;

        const tabNameMapper = {
            ee: 'Insights EE',
            le: 'Insights LE',
            ms: 'Insights MS',
            hs: 'Insights HS',
        };
        const glossaryMapper = {
            ee: this.renderGlossaryButton('ee', 'EE'),
            le: this.renderGlossaryButton('le', 'LE'),
            ms: this.renderGlossaryButton('ms', 'MS'),
            hs: this.renderGlossaryButton('hs', 'HS'),
        };

        const studentTabs = studentInfo.tabs;
        const studentTimepoints = studentInfo.timepoints;

        const tabPropsMapper = {
            ee: selwebTabPropsEE,
            le: selwebTabPropsLE,
            ms: selwebTabPropsMS,
            hs: selwebTabPropsHS,
        };

        return Object.keys(studentTabs)
            .map(tab => {
                return (
                    studentTabs[tab].timepoints.size > 0 && {
                        name: tab,
                        title: tabNameMapper[tab],
                        content: (
                            <div>
                                {this.renderTimepointPills(studentTimepoints, timepoint, this.onClickStudentTimepoint)}
                                {studentTabs[tab].timepoints.has(timepoint) ? (
                                    <div>
                                        {glossaryMapper[tab]}
                                        <Table
                                            key={tab}
                                            csvChunkSize={50} // these queries can be inefficient because of $studentIds filter
                                            checkTimepoints
                                            opensDates={studentTabs[tab].opensDates}
                                            selectedStudentTimepoint={timepoint}
                                            {...tabPropsMapper[tab]}
                                            graphqlVariables={{
                                                groupId: this.groupId,
                                                timepoint,
                                                classId,
                                                ...(this.actingHandle === 'instructor'
                                                    ? { classIds: studentTabs[tab].classes }
                                                    : {}),
                                                studentIds: studentTabs[tab].students,
                                                assignmentId: studentTabs[tab].assignmentId,
                                            }}
                                            noRecordsMessage="No students to display."
                                        />
                                    </div>
                                ) : (
                                    <p>No assignment for this timepoint.</p>
                                )}
                            </div>
                        ),
                    }
                );
            })
            .filter(tab => tab); // removes 'undefined' tabs
    };

    processTeacherTab = (teacherInfo, selectedTimepoint) => {
        const { teacherView } = this.state;
        const { teacherViews } = this.props;

        if (typeof selectedTimepoint !== 'number') {
            selectedTimepoint = Number(selectedTimepoint);
        }

        const isGraphqlTable = teacherViews.find(view => view.handle === teacherView)?.isGraphqlTable;

        // deep copy tableProps so we only add extra columns once
        let tableProps = JSON.parse(JSON.stringify(this.props[teacherView]));

        let table;
        if (!isGraphqlTable) {
            table = <TeacherTableByGroup {...tableProps} timepoint={selectedTimepoint} />;
        } else {
            const startedIndex = tableProps.columns.findIndex(col => col.handle === 'started');
            const completedIndex = tableProps.columns.findIndex(col => col.handle === 'completed');
            tableProps.columns[startedIndex].render = TeacherProgressRender({
                timepoint: selectedTimepoint,
                field: 'started',
            });
            tableProps.columns[completedIndex].render = TeacherProgressRender({
                timepoint: selectedTimepoint,
                field: 'complete',
            });

            table = (
                <Table
                    key={teacherView}
                    {...tableProps}
                    graphqlVariables={{
                        timepoint: ['' + selectedTimepoint], // needs string
                    }}
                    noRecordsMessage="No records to display."
                />
            );
        }

        let viewOptions = teacherViews.map(view => (
            <option value={view.handle} key={view.handle}>
                {view.label}
            </option>
        ));

        return {
            name: 'Teachers',
            title: 'Teachers',
            content: (
                <>
                    <div className={style.controlContainer}>
                        {this.renderTimepointPills(
                            teacherInfo.timepoints,
                            selectedTimepoint,
                            this.onClickTeacherTimepoint
                        )}
                        {viewOptions.length > 1 ? (
                            <div className={style.viewSelect}>
                                <select
                                    name="teacherView"
                                    id="teacherView"
                                    value={teacherView}
                                    onChange={this.onChangeTeacherView}
                                    className={style.teacherSelect}
                                >
                                    {viewOptions}
                                </select>
                            </div>
                        ) : null}
                    </div>
                    <div>{table}</div>
                </>
            ),
        };
    };

    processStudentTimepoints = learnerPlans => {
        let timepoints = new Set();

        learnerPlans.forEach(plan => {
            plan.sessionList.forEach(session => {
                let timepoint = session.timepoint || null;
                if (!timepoint) {
                    return;
                }

                timepoints.add(timepoint);
            });
        });

        return timepoints;
    };

    processStudentPlans = (learnerPlans, defaultAssignmentPlans, classList) => {
        const { classId } = this.props;

        let result = {
            timepoints: new Set(),
            tabs: {
                ee: {},
                le: {},
                ms: {},
                hs: {},
            },
        };

        Object.keys(result.tabs).forEach(key => {
            result.tabs[key] = {
                timepoints: new Set(),
                assignmentId: null,
                classes: [],
                students: [],
                opensDates: {},
            };
        });

        /* {
            <Class groupId>: {
                grades: {
                    "<grade>": [<pawnId>]
                },
                isPlanSelected: <boolean>
            },
            ...
        }
        */
        const classInfoMapper = {};
        classList.forEach(c => {
            const groupId = c?.group?.groupId;
            if (!groupId) {
                return;
            }

            classInfoMapper[groupId] = {};
            classInfoMapper[groupId].isPlanSelected = !!c?.selectedAssignmentPlan?.assignmentPlanId;

            classInfoMapper[groupId].grades = {};
            (c?.childRoles?.learnerList || []).forEach(student => {
                const grade = student?.fields?.grade;
                if (!grade) {
                    return;
                }

                if (grade in classInfoMapper[groupId].grades) {
                    classInfoMapper[groupId].grades[grade].push(student.pawn.pawnId);
                } else {
                    classInfoMapper[groupId].grades[grade] = [student.pawn.pawnId];
                }
            });
        });

        learnerPlans.forEach(plan => {
            let eePicked = false;
            let lePicked = false;
            let msPicked = false;
            let hsPicked = false;

            let gradesWithPlanAsDefault = [];
            Object.values(defaultAssignmentPlans).forEach(currDistrictDefaults => {
                Object.keys(currDistrictDefaults).forEach(gradeKey => {
                    const planId = currDistrictDefaults[gradeKey];
                    if (
                        planId &&
                        parseInt(planId, 10) === parseInt(plan.assignmentPlanId, 10) &&
                        !gradesWithPlanAsDefault.includes(gradeKey)
                    ) {
                        gradesWithPlanAsDefault.push(gradeKey);
                    }
                });
            });
            gradesWithPlanAsDefault = gradesWithPlanAsDefault.map(gradeKey => gradeKey.replace('plan_', ''));

            if (plan.selectedByGroupList.length === 0 && gradesWithPlanAsDefault.length === 0) {
                // Skip if not used in any classes or District default
                return;
            }

            // Teachers can only look at one class at a time (classId)
            //  other roles see mix of all classes
            let classesUsingPlanAsDefault = [];
            if (gradesWithPlanAsDefault.length > 0) {
                Object.keys(classInfoMapper).forEach(groupId => {
                    const { grades, isPlanSelected } = classInfoMapper[groupId];
                    if (isPlanSelected) {
                        return;
                    }

                    const isClassUsingPlan = Object.keys(grades).some(
                        grade =>
                            Array.isArray(grades[grade]) &&
                            grades[grade].length > 0 &&
                            gradesWithPlanAsDefault.includes(grade)
                    );

                    if (isClassUsingPlan) {
                        classesUsingPlanAsDefault.push(parseInt(groupId, 10));
                    }
                });
            }

            let classesUsingPlan = [
                ...classesUsingPlanAsDefault,
                ...plan.selectedByGroupList.map(clss => parseInt(clss.group.groupId, 10)),
            ];

            if (classId) {
                classesUsingPlan = classesUsingPlan.filter(groupId => groupId === classId);
            }

            if (classesUsingPlan.length <= 0) {
                return;
            }

            plan.sessionList.forEach(session => {
                let timepoint = _get(session, 'timepoint', null);
                if (!timepoint) {
                    return;
                }
                let assignmentId = _get(session, 'assignmentId', null);
                let surveyHandles = _get(session, 'settings.options', []) || [];

                result.timepoints.add(timepoint);

                // no `opensDate` means it's always open
                const opensDate = session.opensDate || '1900-01-01';

                surveyHandles.forEach(handle => {
                    if (handle.startsWith('ee_')) {
                        eePicked = true;
                        result.tabs.ee.timepoints.add(timepoint);
                        result.tabs.ee.assignmentId = assignmentId;
                        if (!result.tabs.ee.opensDates[timepoint] || opensDate < result.tabs.ee.opensDates[timepoint]) {
                            result.tabs.ee.opensDates[timepoint] = opensDate;
                        }
                    } else if (handle.startsWith('le_')) {
                        lePicked = true;
                        result.tabs.le.timepoints.add(timepoint);
                        result.tabs.le.assignmentId = assignmentId;
                        if (!result.tabs.le.opensDates[timepoint] || opensDate < result.tabs.le.opensDates[timepoint]) {
                            result.tabs.le.opensDates[timepoint] = opensDate;
                        }
                    } else if (handle.startsWith('ms_')) {
                        msPicked = true;
                        result.tabs.ms.timepoints.add(timepoint);
                        result.tabs.ms.assignmentId = assignmentId;
                        if (!result.tabs.ms.opensDates[timepoint] || opensDate < result.tabs.ms.opensDates[timepoint]) {
                            result.tabs.ms.opensDates[timepoint] = opensDate;
                        }
                    } else if (handle.startsWith('hs_')) {
                        hsPicked = true;
                        result.tabs.hs.timepoints.add(timepoint);
                        result.tabs.hs.assignmentId = assignmentId;
                        if (!result.tabs.hs.opensDates[timepoint] || opensDate < result.tabs.hs.opensDates[timepoint]) {
                            result.tabs.hs.opensDates[timepoint] = opensDate;
                        }
                    }
                });
            });

            classesUsingPlan.forEach(groupId => {
                const classInfo = classInfoMapper[groupId];

                function addStudentsToResult(tabKey) {
                    if (!classInfo) {
                        return;
                    }

                    // add all learners
                    if (classInfo.isPlanSelected) {
                        Object.values(classInfo.grades).forEach(arr => {
                            if (!Array.isArray(arr)) {
                                return;
                            }

                            arr.forEach(studentPawnId => {
                                result.tabs[tabKey].students.push(studentPawnId);
                            });
                        });
                    }

                    // add only learners with plan as default
                    else {
                        gradesWithPlanAsDefault.forEach(g => {
                            if (!(g in classInfo.grades) || !Array.isArray(classInfo.grades[g])) {
                                return;
                            }

                            classInfo.grades[g].forEach(studentPawnId => {
                                result.tabs[tabKey].students.push(studentPawnId);
                            });
                        });
                    }
                }

                if (eePicked) {
                    result.tabs.ee.classes.push(groupId);
                    addStudentsToResult('ee');
                }
                if (lePicked) {
                    result.tabs.le.classes.push(groupId);
                    addStudentsToResult('le');
                }
                if (msPicked) {
                    result.tabs.ms.classes.push(groupId);
                    addStudentsToResult('ms');
                }
                if (hsPicked) {
                    result.tabs.hs.classes.push(groupId);
                    addStudentsToResult('hs');
                }
            });
        });

        return result;
    };

    processTeacherPlans = (gqlData, defaultAssignmentPlans) => {
        const result = {
            isShowTab: false,
            timepoints: new Set(),
        };
        if (this.actingHandle === 'instructor') {
            return result;
        }

        const teacherPlanList = _get(gqlData, 'data.groups.organization.instructorPlanList', []) || [];

        // No Teacher plans even available in this Project, so we know none were selected
        if (teacherPlanList.length <= 0) {
            return result;
        }

        let planIdsInUse = new Set();
        let planMapper = {};
        // Gets all plans selected at School level
        teacherPlanList.forEach(availablePlan => {
            const planId = parseInt(availablePlan.assignmentPlanId, 10);
            planMapper[planId] = availablePlan;
            if (Array.isArray(availablePlan?.selectedByGroupList) && availablePlan.selectedByGroupList.length > 0) {
                planIdsInUse.add(planId);
            }
        });
        // Also check plans selected as District defaults;
        Object.values(defaultAssignmentPlans).forEach(settings => {
            if (!Number.isNaN(parseInt(settings.plan_teacher, 10))) {
                planIdsInUse.add(parseInt(settings.plan_teacher, 10));
            }
            if (!Number.isNaN(parseInt(settings.plan_studentRatingForm, 10))) {
                planIdsInUse.add(parseInt(settings.plan_studentRatingForm, 10));
            }
        });

        // No plans being used, so don't show teacher tab
        if (planIdsInUse.size <= 0) {
            return result;
        }

        result.isShowTab = true;
        for (const planId of planIdsInUse) {
            const planDef = planMapper[planId];
            if (!Array.isArray(planDef?.sessionList)) {
                continue;
            }

            planDef.sessionList.forEach(session => {
                result.timepoints.add(parseInt(session.timepoint, 10));
            });
        }

        return result;
    };

    renderTimepointPills = (timepoints, selectedTimepoint, onClick) => {
        return (
            timepoints.size > 1 && (
                <div className={`btn-group ${style.pillButtons}`} role="group">
                    {[...timepoints]
                        .sort((a, b) => a - b)
                        .map(timepoint => (
                            <button
                                key={timepoint}
                                type="button"
                                className={`btn ${selectedTimepoint === timepoint ? 'btn-primary' : 'btn-default'}`}
                                onClick={() => onClick(timepoint)}
                            >
                                Time {timepoint}
                            </button>
                        ))}
                </div>
            )
        );
    };

    renderGlossaryButton = (type, name) => {
        return (
            <div className={style.glossaryContainer}>
                <button type="button" className="btn btn-primary" onClick={() => this.openGlossary(type)}>
                    {name} Glossary
                </button>
            </div>
        );
    };

    openGlossary = sessionType => {
        const linkMap = {
            ee: 'https://xsel-labs.com/wp-content/uploads/2021/02/report_appendix_EE.pdf',
            le: 'https://xsel-labs.com/wp-content/uploads/2021/02/report_appendix_le.pdf',
            ms: 'https://xsel-labs.com/wp-content/uploads/2021/02/report_appendix_mshs.pdf',
            hs: 'https://xsel-labs.com/wp-content/uploads/2021/02/report_appendix_mshs.pdf',
        };

        window.open(linkMap[sessionType], '_blank');
    };

    render() {
        // TO DO parse url params more officially
        let initialTab = this.props?.location?.search;
        if (initialTab) {
            initialTab = initialTab.replace('?initialTab=', '');
            if (!this.state.tabs.some(t => t.name === initialTab)) {
                initialTab = null;
            }
        }

        return (
            <Loader loading={this.state.isLoading}>
                {this.state.tabs.length > 0 && (
                    <div className={style.tabsContainer}>
                        <Tabs tabList={this.state.tabs} initialTab={initialTab} />
                    </div>
                )}
            </Loader>
        );
    }
}
