import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { Dashboard as AppdefDashboard, AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';

import AccountActivate from './components/Activation';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome';
import UberadminManage from './pages/UberadminManage';

// Components
import AssignmentPreview from 'bundle-loader?lazy!./components/AssignmentPreview';
import AssignmentPreviewList from 'bundle-loader?lazy!./components/AssignmentPreviewList';
import BrowserTest from 'bundle-loader?lazy!./components/BrowserTest';
import StudentLanding from 'bundle-loader?lazy!./components/StudentLanding';
import StudentAssignment from 'bundle-loader?lazy!./components/StudentAssignment';
import StudentRedirect from 'bundle-loader?lazy!./components/StudentRedirect';
import TeacherAssignment from 'bundle-loader?lazy!./components/TeacherAssignment';
import TeacherStudentRatingForm from 'bundle-loader?lazy!./components/TeacherStudentRatingForm';
import NightlySync from 'bundle-loader?lazy!./components/NightlySync';
import Resources from 'bundle-loader?lazy!cccisd-laravel-resources';
import ErrorMessage from 'bundle-loader?lazy!./components/ErrorMessage';

import RequireSecureWrapper from './components/RequireSecureWrapper';
import SetInsecureWrapper from './components/SetInsecureWrapper';
import CoequalChoices from './components/CoequalChoices';
import TeacherAdministrative from './components/TeacherAdministrative';
import Administrative from './components/Administrative';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Create Secure/SetInsecure versions of Components
const SecuredAppdefDashboard = props => <RequireSecureWrapper wrappedComponent={AppdefDashboard} {...props} />;
const SetInsecureAppdefDashboard = props => <SetInsecureWrapper wrappedComponent={AppdefDashboard} {...props} />;
const SecuredTeacherAdministrative = props => (
    <RequireSecureWrapper wrappedComponent={TeacherAdministrative} {...props} />
);
const SecuredAdministrative = props => <RequireSecureWrapper wrappedComponent={Administrative} {...props} />;

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({
    className: 'container-fluid',
})(MainLayout);
const MainLayoutSecondaryNav = defaultProps({
    showSecondaryNav: true,
})(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);
const StudentLayout = defaultProps({
    className: 'container-fluid',
    showFooter: false,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefDashboard,
    AppdefManage,
    AssignmentPreview,
    AssignmentPreviewList,
    BrowserTest,
    CoequalChoices,
    NightlySync,
    SecuredAppdefDashboard,
    SetInsecureAppdefDashboard,
    SecuredTeacherAdministrative,
    SecuredAdministrative,
    StudentLanding,
    StudentAssignment,
    StudentRedirect,
    TeacherAssignment,
    TeacherStudentRatingForm,
    UberadminManage,
    Welcome,
    ErrorMessage,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
    StudentLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout]
                            ? layoutMatch[route.layout]
                            : MainLayoutFluidSecondaryNav
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute path="/test" component={BrowserTest} />
        <AppRoute path="/error/:encodedError" component={ErrorMessage} />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}

        <AppRoute path="/account/activate/:activationHash?" component={AccountActivate} />
        <AppRoute path="/account" component={Nexus} />
        <AppRoute path="/downloads" component={Resources} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
