import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Footer from 'cccisd-footer';
import Header from '../../components/Header';
import { setInsecure } from '../../reducers/security.js';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

class FluidLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        isFixed: PropTypes.bool,
        isInsecure: PropTypes.bool,
        className: PropTypes.string,

        // From redux
        isSecure: PropTypes.bool.isRequired,
        lastLogin: PropTypes.string.isRequired,
        setInsecure: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: 'container',
    };

    componentDidMount() {
        const { isInsecure, lastLogin, isSecure } = this.props;
        if (Fortress.user.actual.role.handle === 'guest') {
            return;
        }
        if (!isInsecure && lastLogin === Fortress.user.actual.user.last_login && !isSecure) {
            window.location = Boilerplate.url('/api/user/logout');
        }
        if (isInsecure) {
            this.props.setInsecure();
        }
    }

    render() {
        const { className, children } = this.props;

        return (
            <div className={style.wrapper}>
                <Header className={className} />

                <div className={style.body}>
                    <div className={className}>{children}</div>
                </div>

                {/* Hide Footer for students - they should not be able to navigate anywhere else */}
                {Fortress.user.acting.role.handle !== 'learner' && <Footer className={className} />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isSecure: state.app.security.isSecure,
    lastLogin: state.app.security.lastLogin,
});

export default connect(mapStateToProps, { setInsecure })(FluidLayout);
