import React from 'react';
import copy from 'copy-to-clipboard';
import IconCopy from 'cccisd-icons/copy3';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';

const Boilerplate = window.cccisd.boilerplate;

const copyOnClick = url => {
    copy(url);

    notification({
        message: 'Copied to clipboard!',
        type: 'success',
        duration: 4000,
    });
};

export default settings => {
    return ({ row }) => {
        const prefix = row['fields.prefix'];
        const isSamlEnabled = !!row['group.settings.saml.enabled'];
        if (!isSamlEnabled) {
            return <span />;
        }

        const registrationUrl = Boilerplate.url(`api/sso/saml/login/${prefix}`);
        return (
            <Tooltip title={'Copy ' + registrationUrl}>
                <button type="button" className="btn btn-xs btn-info" onClick={() => copyOnClick(registrationUrl)}>
                    <IconCopy />
                </button>
            </Tooltip>
        );
    };
};
